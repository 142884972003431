body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  margin: 5px;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.clickable {
  cursor: pointer;
}

.icon {
  height: 25px;
  width: 25px;
  margin: auto;
  margin-left: 3px;
  margin-top: 2.5px;
}

.dimmed {
  opacity: 0.3;
}

.flex-row {
  display: inline-block;
}
