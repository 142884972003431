.flash-card-container {
  width: 90vw;
  height: 60vh;
  align-items: center;
  display: flex;
  margin: auto;
}

.flash-card {
  display: grid;
  align-items: center;
  width: 90%;
  height: 90%;
  margin: auto;
  max-width: 1000px;
  max-height: 600px;
  border: 2px solid black;
  border-radius: 5px;
  overflow-y: auto;
  position: relative;
  padding-left: 35px;
  padding-right: 35px;
}

.flash-card-flash {
  display: flex;
  height: 100%;
  width: 100%;
  font-size: 2.5em;
  align-items: center;
  justify-content: center;
}

.flash-card-back {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 40px);
  width: 100%;
  font-size: 1.1em;
  margin-top: 40px;
}

.flash-card-detail-button {
  position: absolute;
  top: 10px;
  right: 55px;
}

.flash-card-nav-button-container {
  position: absolute;
  background-color: inherit;
  border: 0px;
  padding: 5px;
  top: 0px;
  width: 40px;
}
.flash-card-nav-button-container:hover {
  background-color: rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.flash-card-nav-button {
  background-color: transparent;
  border: none;
  height: 40px;
  width: 40px;
  margin: 0px;
  padding: 0px;
  border-radius: 20px;
}

.flash-acronyms {
  background-color: gainsboro;
  color: black;
}
.flash-aerodynamics {
  background-color: darkviolet;
  color: white;
}
.flash-airspaces {
  background-color: aqua;
  color: black;
}
.flash-airport-operations {
  background-color: navy;
  color: white;
}
.flash-emergency-procedures {
  background-color: orangered;
  color: white;
}
.flash-far-aim {
  background-color: mediumslateblue;
  color: white;
}
.flash-r22 {
  background-color: cornsilk;
  color: black;
}
.flash-warning-lights {
  background-color: yellow;
  color: black;
}
.flash-weather {
  background-color: skyblue;
  color: black;
}
