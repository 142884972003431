.modal-container {
  display: flex;
  justify-content: center;
}

.modal-overlay {
  opacity: 1;
  position: absolute;
  margin: auto;
  top: 50px;
  z-index: 99;
  border-radius: 5px;
  border: 1px solid black;
  box-shadow: 5px 5px 5px grey;
  background-color: pink;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 80%;
  max-height: 80%;
}

.modal-header {
  text-align: center;
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 1.2em;
  font-weight: bold;
  word-wrap: break-word;
}

.modal-close {
  position: absolute;
  right: 5px;
  top: 5px;
  height: 30px;
  width: 30px;
  border-radius: 25px;
}
.modal-close:hover {
  background-color: rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.modal-content {
  position: relative;
  margin: auto;
  word-wrap: break-word;
  padding-bottom: 10px;
  padding-top: 10px;
}
